import React from "react";

const Contact = () => {
  return (
    <>
      <div class="header-container-sm enthusiast-behavior d-flex justify-content-center align-items-center">
        <h1>Ways to get in touch</h1>
      </div>
      <div className="content container full-width white-bg">
        <div className="inner-content mx-auto">
          <div className="row pt-5 pb-md-5 pb-0">
            <div className="col-md-6 d-flex flex-column justify-content-center align-items-center">
              <h3 className="enthusiast-behavior">Contact Numbers</h3>
              <a href="tel:01314400577">Aim High - 0131 440 0577</a>
              <a href="tel:07973582851">Mobile - 07973 582851</a>
            </div>
            <div className="col-md-6 my-5 my-md-0 d-flex flex-column justify-content-center align-items-center">
              <h3 className="enthusiast-behavior">Social links</h3>
              <div className="row">
                <a
                  href="https://www.facebook.com/AimHighWaxingBeauty"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i class="fab fa-facebook large footer-facebook primary-color-text mr-2"></i>
                </a>
                <a
                  href="https://www.instagram.com/aimhighwaxingbeauty/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i class="fab fa-instagram-square large footer-insta primary-color-text"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 py-md-0 py-3 d-flex flex-column justify-content-center">
              <h2 className="enthusiast-behavior text-center">Contact me here</h2>

              <p className="text-center">For all enquires please fill out the following form.</p>

              <p className="text-center">Please allow 24-48 hours for a reply.</p>

              <p className="text-center">Thank you.</p>

              <p className="text-center">PLEASE NOTE - I AM NOT TAKING BOOKINGS FOR NEW CLIENTS</p>
            </div>
            <div className="col-md-9 my-3 mx-auto">
              <form
                id="contact"
                className="contact needs-validation"
                action="https://submit-form.com/ZrY8x7Im"
                novalidate
              >
                <input
                  type="hidden"
                  name="_feedback.success.title"
                  value="Thanks for your message!"
                />
                <input
                  type="hidden"
                  name="_feedback.success.message"
                  value="Please allow up to 48 hours for a reply."
                />
                <input type="hidden" name="_email.subject" value="You have a new message!" />
                <input
                  type="hidden"
                  name="_email.from"
                  value="Contact - Aim High Waxing & Beauty"
                />
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-pencil-alt"></i>
                    </span>
                  </div>
                  <input
                    name="Subject"
                    type="text"
                    className="form-control"
                    placeholder="Subject"
                  />
                </div>
                <div className="form-row">
                  <div className="input-group col-md-6 mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fa fa-user icon"></i>
                      </span>
                    </div>
                    <input
                      name="Name"
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      required
                    />
                  </div>
                  <div className="input-group col-md-6 mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fas fa-phone"></i>
                      </span>
                    </div>
                    <input
                      name="Phone"
                      type="text"
                      className="form-control"
                      placeholder="Phone number"
                      required
                    />
                  </div>
                </div>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="far fa-envelope"></i>
                    </span>
                  </div>
                  <input name="Email" type="email" className="form-control" placeholder="Email" />
                </div>
                <div className="input-group pb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-info"></i>
                    </span>
                  </div>
                  <textarea
                    className="form-control"
                    name="Message"
                    placeholder="Your message..."
                    required
                  ></textarea>
                </div>
                <div
                  className="g-recaptcha"
                  data-sitekey="6LeE8N0ZAAAAAMjP9_oOU9yWuue04QZpbsOvxmOn"
                  data-theme="light"
                  style={{
                    transform: "scale(0.77)",
                    "-webkit-transform": "scale(0.77)",
                    "transform-origin": "0 0",
                    "-webkit-transform-origin": "0 0",
                  }}
                ></div>
                <button className="btn primary-color" type="submit">
                  Send
                </button>
              </form>
            </div>
          </div>
          <hr className="my-md-3 my-4" />
          <div className="row">
            <div className="col-md-3 ml-auto d-flex flex-column justify-content-center">
              <div className="row">
                <div className="col-md-12 col-6 d-flex flex-column justify-content-center">
                  <h2 className="enthusiast-behavior text-center my-3">Opening times:</h2>
                  <p className="text-center">
                    Monday - CLOSED <br />
                    Tuesday - 10:00 - 17:00 <br />
                    Wednesday - 10:00 - 18:00 <br />
                    Thursday - 10:00 - 19:00 <br />
                    Friday - 10:00 - 17:00 <br />
                    Saturday - 10:00 - 13:00 <br />
                    Sunday - CLOSED
                  </p>
                </div>
                <div className="col-md-12 col-6 d-flex flex-column justify-content-center">
                  <h2 className="enthusiast-behavior text-center my-3">
                    Address:
                    <br />
                  </h2>
                  <p className="text-center">
                    Aim High Waxing & Beauty
                    <br />
                    9 High St
                    <br />
                    Loanhead <br />
                    EH20 9RH
                    <br />
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-9 px-2 py-2 d-flex flex-column justify-content-center mr-auto">
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4476.326798683725!2d-3.151006539197789!3d55.877180174174406!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x428f8090bd81385b!2sAim%20High%20Waxing%20%26%20Beauty!5e0!3m2!1sen!2suk!4v1604519264083!5m2!1sen!2suk"
                className="map"
                frameborder="0"
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
