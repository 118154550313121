import React from "react";
import { Routes as Router, Route } from "react-router-dom";

import Home from "../screens/Home";
import About from "../screens/About";
import Contact from "../screens/Contact";
import Treatments from "../screens/Treatments";

const Routes = () => {
  return (
    <Router>
      <Route path="/" element={<Home />} />
      <Route path="about" element={<About />} />
      <Route path="treatments" element={<Treatments />} />
      <Route path="contact" element={<Contact />} />
      <Route
        path="*"
        element={
          <main style={{ padding: "1rem" }}>
            <p>There's nothing here!</p>
          </main>
        }
      />
    </Router>
  );
};

export default Routes;
