import React from "react";

import headerLogo from "../assets/img/logo-tagline.png";
import interiorOne from "../assets/img/interior.jpg";
import interiorTwo from "../assets/img/interior2.jpg";

const Home = () => {
  return (
    <>
      <div className="header-container d-flex justify-content-center align-items-center full-height">
        <img src={headerLogo} alt="salon logo" />
      </div>
      <div className="content container full-width white-bg">
        <div className="inner-content mx-auto">
          <div className="row text-justify justify-content-center mx-3">
            <h2 className="enthusiast-behavior my-5">Welcome to Aim High Waxing & Beauty</h2>
          </div>
          <div className="row mt-3">
            <div className="col-7 pr-0 d-flex align-items-center">
              <div className="home text-justify pb-3">
                A fun, friendly salon located in Loanhead.
                <br />
                <br />
                The salon opened in July 2020 and has been designed to be very modern & bold, using
                yellow and navy decor throughout.
                <br />
                <br />
                Within the salon I use some of the best brands available in the beauty industry.
                <br />
                <br />
                I specialise in female waxing using Lycon Wax which is a professional UK wax brand.
                Super gentle on the skin, and capable of removing hairs as short as 1mm.
                <br />
              </div>
            </div>
            <div className="col-5 d-flex justify-content-center flex-column">
              <img className="row-img" src={interiorOne} alt="salon seating area" />
            </div>
          </div>
          <hr className="my-5" />
          <div className="row">
            <div className="col-7 pl-0 order-2 d-flex align-items-center">
              <div className="home text-justify pb-3">
                My other main treatments I carry out are for nails (natural only!) I use The Gel
                Bottle Inc. products for up to 3 weeks lasting colour. If you find you have weaker
                nails, Builder In A Bottle (BIAB) can be applied as an overlay on your nails, and a
                colour can be applied over the top too if you wish. BIAB acts as a strengthening
                layer and will encourage your nails to grow.
                <br />
                <br />
                I also offer lash lifting, tinting and threading.
                <br />
                <br />
                Pop over to the treatments page for my full price list and to book!
                <br />
              </div>
            </div>
            <div className="col-5 order-1 d-flex justify-content-center flex-column">
              <img className="row-img" src={interiorTwo} alt="salon work stations" />
            </div>
          </div>
          <hr className="my-5" />
        </div>
      </div>
    </>
  );
};

export default Home;
