import React from "react";
import { Link } from "react-router-dom";

const Nav = () => {
  return (
    <nav className="navbar py-3 navbar-expand-lg overpass primary-color">
      <button
        className=" ml-auto navbar-toggler collapsed"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="icon-bar top-bar"></span>
        <span className="icon-bar middle-bar"></span>
        <span className="icon-bar bottom-bar"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNavDropdown">
        <ul className="navbar-nav mx-auto">
          <li className="nav-item mx-md-3 mx-0">
            <Link to="/">Home</Link>
          </li>

          <li className="nav-item mx-md-3 mx-0">
            <Link to="/about">About</Link>
          </li>
          <li className="nav-item mx-md-3 mx-0">
            <Link to="/treatments">Treatments</Link>
          </li>
          <li className="nav-item mx-md-3 mx-0">
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Nav;
