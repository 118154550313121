import React from "react";

const Footer = () => {
  return (
    <div class="container full-width auto-height footer mt-3 py-3" id="footer">
      <div class="row primary-color no-padding full-width d-flex justify-content-center align-items-center">
        <div class="col-4 mx-auto align-middle medium-w text-center enthusiast-behavior text-outline">
          Aim High Waxing & Beauty
        </div>
        <div class="col-4 text-center">
          <a
            class="xsmall secondary-color-text py-3"
            href="https://www.instagram.com/elementsdevelopment_/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Designed and developed by @elementsdevelopment
          </a>
        </div>
        <div class="col-4 py-2 text-center">
          <a
            href="https://www.facebook.com/AimHighWaxingBeauty"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="fab fa-facebook-square large footer-facebook secondary-color-text mx-2"></i>
          </a>
          <a
            href="https://www.instagram.com/aimhighwaxingbeauty/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="fab fa-instagram-square large footer-insta secondary-color-text"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
