import React from "react";

import Amy from "../assets/img/amy.png";

const About = () => {
  return (
    <>
      <div class="header-container-sm enthusiast-behavior d-flex justify-content-center align-items-center">
        <h1>About</h1>
      </div>
      <div class="content container full-width white-bg">
        <div class="inner-content mx-auto">
          <div class="row enthusiast-behavior justify-content-center py-3">
            <h2>A bit about me</h2>
          </div>
          <div class="row text-justify py-5">
            <div class="col-md-4 d-flex justify-content-end order-md-1">
              <p>
                Hello! I'm Amy - owner of Aim High Waxing & Beauty.
                <br />
                <br />
                I'm a fully qualified beauty therapist having graduated in 2016. Since then, I have
                grown my own business and then decided to take the step to open my own salon in July
                2020 (only slightly later than planned due to a pandemic...!)
                <br />
                <br />
                Although fully qualified, my passion has always been with waxing, so upon opening
                the salon I decided to work from a limited treatment list so I can focus on the
                treatments I enjoy carrying out.
              </p>
            </div>
            <div class="col-md-4 d-flex align-items-end order-md-2">
              <div class="polaroid px-3 py-3">
                <img src={Amy} alt="Handrawn portrait of Amy" />
                <div class="container">
                  <p class="enthusiast-behavior medium pt-3 text-center">Amy</p>
                </div>
              </div>
            </div>
            <div class="col-md-4 d-flex justify-content-start order-md-3 pt-md-0 pt-3">
              I am a very easy going person and try to have the best time with my clients, usually
              laughing constantly and generally having a carry on! It's important for me that my
              clients really know who I am as I carry out such personal treatments, so I aim for you
              all to be as relaxed as possible around me.
              <br />
              <br />
              I wanted the salon to be a place where my clients can just be comfortable and have a
              great time, and that definitely is the case!
              <br />
              <br />
              Come on in to my salon, you'll have an amazing visit! ;)
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
