import React from "react";
import { Link } from "react-router-dom";

const Treatments = () => {
  return (
    <>
      <div class="header-container-sm enthusiast-behavior d-flex justify-content-center align-items-center">
        <h1>Treatments</h1>
      </div>
      <div class="content container full-width text-justify white-bg pt-4">
        <div class="inner-content mx-auto">
          <div class="row">
            <div class="col">
              <h2 class="enthusiast-behavior py-3">Treatments & prices</h2>
              <p>
                All current and up to date prices and treatments can be found on the booking site
                which is linked at the bottom of this page.
              </p>
              <p>
                If you have any questions, please feel free to
                <Link className="primary-color-text" to="/contact">
                  contact me
                </Link>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 order-md-4 order-5">
              <div class="row align-items-center mt-5 mt-md-0">
                <div class="col-auto">
                  <h4 class="my-3">CANCELLATION POLICY</h4>
                </div>
                <div class="col">
                  <hr />
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-12">
                      Please give at least 48 hours notice if you wish to rearrange, cancel part of,
                      or cancel your entire appointment. If less than 48 hours notice is given, you
                      will be charged in full for your appointment. If you are late to your
                      appointment, you may not receive all, or any, of your treatments booked,
                      depending on the time available and you will still be charged in full. No show
                      appointments are charged in full. No future appointments will be accepted if
                      any payment is not received.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-center py-md-4 py-5">
            <div class="col-md-2 col-12 d-none d-md-block">
              <hr />
            </div>
            <div class="col-auto d-flex align-items-center">
              <a
                href="https://aimhighwaxingbeauty.book.app"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button class="btn primary-color">
                  <i class="far fa-calendar-plus mr-2"></i>Click here to book your appointment
                </button>
              </a>
            </div>
            <div class="col-md-2 col-12 d-none d-md-block">
              <hr />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Treatments;
